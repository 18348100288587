import classNames from 'classnames';
import React from 'react';

import { MailOrderIconProps } from './mail-order-icon.props';

const MailOrderIcon = ({ className, style }: MailOrderIconProps) => {
    const classes = classNames('mail-order-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M0 5.14287C0 4.19611 0.767511 3.42859 1.71429 3.42859H22.2857C23.2325 3.42859 24 4.19611 24 5.14287V18.8572C24 19.804 23.2325 20.5714 22.2857 20.5714H1.71429C0.767513 20.5714 0 19.804 0 18.8572V5.14287Z"
                    fill="#5CC4ED"
                />
                <path
                    d="M7.71425 12C8.77938 12 9.64282 11.1366 9.64282 10.0715C9.64282 9.00634 8.77938 8.14289 7.71425 8.14289C6.64913 8.14289 5.78568 9.00634 5.78568 10.0715C5.78568 11.1366 6.64913 12 7.71425 12Z"
                    fill="#1A497F"
                />
                <path
                    d="M7.71421 13.2857C6.21047 13.2857 4.85747 13.9311 3.91709 14.96C3.58306 15.3255 3.8788 15.8572 4.37392 15.8572H11.0545C11.5496 15.8572 11.8454 15.3255 11.5113 14.96C10.5709 13.9311 9.21795 13.2857 7.71421 13.2857Z"
                    fill="#1A497F"
                />
                <path d="M15.1 12H19.9M17.5 14.4V9.6" stroke="white" strokeWidth="1.8" strokeLinecap="round" />
            </svg>
        </div>
    );
};

export default MailOrderIcon;
