import classNames from 'classnames';
import React from 'react';

import { PetsIconProps } from './pets-icon.props';

const PetsIcon = ({ className, style }: PetsIconProps) => {
    const classes = classNames('pets-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 10.2857C9.9414 10.2857 8.41927 11.3892 7.44453 12.7587C6.48326 14.1093 6 15.7833 6 17.1428C6 18.727 6.95191 19.8283 8.1186 20.4898C9.26801 21.1417 10.7084 21.4285 12 21.4285C13.2916 21.4285 14.732 21.1417 15.8814 20.4898C17.0481 19.8283 18 18.727 18 17.1428C18 15.7833 17.5167 14.1093 16.5555 12.7587C15.5807 11.3892 14.0586 10.2857 12 10.2857Z"
                    fill="#5CC4ED"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M7.71429 2.57141C6.86515 2.57141 6.20131 3.08767 5.78892 3.70625C5.37254 4.33082 5.14286 5.14428 5.14286 5.99998C5.14286 6.85569 5.37254 7.66915 5.78892 8.29371C6.20131 8.9123 6.86515 9.42855 7.71429 9.42855C8.56342 9.42855 9.22726 8.9123 9.63965 8.29371C10.056 7.66915 10.2857 6.85569 10.2857 5.99998C10.2857 5.14428 10.056 4.33082 9.63965 3.70625C9.22726 3.08767 8.56342 2.57141 7.71429 2.57141ZM16.2857 2.57141C15.4366 2.57141 14.7727 3.08767 14.3603 3.70625C13.944 4.33082 13.7143 5.14428 13.7143 5.99998C13.7143 6.85569 13.944 7.66915 14.3603 8.29371C14.7727 8.9123 15.4366 9.42855 16.2857 9.42855C17.1349 9.42855 17.7987 8.9123 18.211 8.29371C18.6274 7.66915 18.8571 6.85569 18.8571 5.99998C18.8571 5.14428 18.6274 4.33082 18.211 3.70625C17.7987 3.08767 17.1349 2.57141 16.2857 2.57141ZM2.57143 10.2857C1.72229 10.2857 1.05845 10.802 0.646059 11.4205C0.229678 12.0451 0 12.8586 0 13.7143C0 14.57 0.229678 15.3834 0.646059 16.008C1.05845 16.6266 1.72229 17.1428 2.57143 17.1428C3.42057 17.1428 4.08441 16.6266 4.49679 16.008C4.91318 15.3834 5.14286 14.57 5.14286 13.7143C5.14286 12.8586 4.91318 12.0451 4.49679 11.4205C4.08441 10.802 3.42057 10.2857 2.57143 10.2857ZM21.4286 10.2857C20.5795 10.2857 19.9155 10.802 19.5033 11.4205C19.0869 12.0451 18.8571 12.8586 18.8571 13.7143C18.8571 14.57 19.0869 15.3834 19.5033 16.008C19.9155 16.6266 20.5795 17.1428 21.4286 17.1428C22.2777 17.1428 22.9416 16.6266 23.3539 16.008C23.7703 15.3834 24 14.57 24 13.7143C24 12.8586 23.7703 12.0451 23.3539 11.4205C22.9416 10.802 22.2777 10.2857 21.4286 10.2857Z"
                    fill="#03A4DC"
                />
            </svg>
        </div>
    );
};

export default PetsIcon;
