import classNames from 'classnames';
import React from 'react';

import { BirdiCashIconProps } from './birdi-cash-icon.props';

const BirdiCashIcon = ({ className, style }: BirdiCashIconProps) => {
    const classes = classNames('birdi-cash-icon', className);

    return (
        <div className={classes} style={style}>
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="8.5" cy="6" r="3.5" fill="#5CC4ED" />
                <path
                    d="M10.5 20.5V16.7428C10.5 15.1286 11.8494 14 13.2857 14H13.5333C12.0964 12.4515 10.1469 11.5 7.99999 11.5C4.29749 11.5 1.18245 15.0551 0.270328 18.5241C-0.0105568 19.5923 0.896591 20.5 2.00116 20.5H10.5Z"
                    fill="#5CC4ED"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M12 16.7428C12 16.0359 12.5967 15.5 13.2857 15.5H22.7143C23.4033 15.5 24 16.0359 24 16.7428V22.4422C24 23.1491 23.4033 23.685 22.7143 23.685H13.2857C12.5967 23.685 12 23.1491 12 22.4422V16.7428ZM16.2 19.5925C16.2 18.5983 17.0059 17.7925 18 17.7925C18.9941 17.7925 19.8 18.5983 19.8 19.5925C19.8 20.5866 18.9941 21.3925 18 21.3925C17.0059 21.3925 16.2 20.5866 16.2 19.5925ZM14.5746 19.5925C14.5746 19.9484 14.2861 20.2369 13.9301 20.2369C13.5742 20.2369 13.2857 19.9484 13.2857 19.5925C13.2857 19.2366 13.5742 18.948 13.9301 18.948C14.2861 18.948 14.5746 19.2366 14.5746 19.5925ZM22.0699 20.2369C22.4258 20.2369 22.7143 19.9484 22.7143 19.5925C22.7143 19.2366 22.4258 18.948 22.0699 18.948C21.714 18.948 21.4255 19.2366 21.4255 19.5925C21.4255 19.9484 21.714 20.2369 22.0699 20.2369Z"
                    fill="#03A4DC"
                />
            </svg>
        </div>
    );
};

export default BirdiCashIcon;
