import classNames from 'classnames';

import ClickableCard from 'components/clickable-card';
import { ClickableCardProps } from 'components/clickable-card/clickable-card.props';

// Styles
import './welcome-card.styles.scss';

export interface WelcomeCardListProps {
    cards: ClickableCardProps[];
    className?: string;
}

const WelcomeCardsList = ({ cards, className }: WelcomeCardListProps) => {
    const containerClasses = classNames('welcome-cards-section', className);

    if (cards.length === 0) {
        return null;
    }

    return (
        <div className={containerClasses}>
            {cards.map((card) => (
                <ClickableCard key={card.title} {...card} />
            ))}
        </div>
    );
};

export default WelcomeCardsList;
