import classNames from 'classnames';
import { navigate } from 'gatsby';

import BirdiCashIcon from 'ui-kit/icons/birdi-cash/birdi-cash-icon';
import BirdiPriceIcon from 'ui-kit/icons/birdi-price/birdi-price-icon';
import ChevronIcon from 'ui-kit/icons/chevron-icon/chevron-icon';
import MailOrderIcon from 'ui-kit/icons/mail-order/mail-order-icon';
import PetsIcon from 'ui-kit/icons/pets/pets-icon';

import { ClickableCardProps } from './clickable-card.props';
// Styles
import './clickable-card.styles.scss';

const ClickableCard = ({ title, description, icon, footNote, className, to }: ClickableCardProps) => {
    const containerClasses = classNames('clickable-card', { hasFootNote: footNote !== undefined }, className);

    const mapIconSrc = (icon: ClickableCardProps['icon']) => {
        switch (icon) {
            case 'birdi-cash':
                return <BirdiCashIcon />;
            case 'birdi-price':
                return <BirdiPriceIcon />;
            case 'pets':
                return <PetsIcon />;
            case 'mail-order':
                return <MailOrderIcon />;
        }
    };

    const handleNavigate = (path: string) => {
        navigate(path);
    };

    return (
        <div className={containerClasses}>
            <button onClick={() => handleNavigate(to)} role="link" type="button">
                <div className="clickable-card-content">
                    <div className="clickable-card-icon">{mapIconSrc(icon)}</div>
                    <div className="clickable-card-info">
                        <span className="clickable-card-info-title">{title}</span>
                        <small className="clickable-card-info-description">{description}</small>
                    </div>
                    <div className="clickable-card-icon">
                        <ChevronIcon direction="right" />
                    </div>
                </div>

                {footNote && (
                    <div className="clickable-card-footnote">
                        <span>{footNote}</span>
                    </div>
                )}
            </button>
        </div>
    );
};

export default ClickableCard;
